import React, { FunctionComponent, ReactElement } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { SEO, GeneralJumbotron, SummaryTemplate, CallToAction } from '../components';
import { MainLayout } from '../layouts';
import { ChiropractorsPageImagesQuery } from '../shared/interfaces';

const ChiropractorsPage: FunctionComponent = (): ReactElement => {
    const queryResult = useStaticQuery<ChiropractorsPageImagesQuery>(graphql`
        query {
            chiropractorsBackgroundImage: file(name: {eq: "chiropractorBackground"}, extension: {eq: "jpg"}) {
                publicURL
            }
            chiropractorsSummaryImage: file(name: {eq: "chiropractorsSummary"}, extension: {eq: "png"}) {
                publicURL
            }
        }
    `);

    return (
        <MainLayout>
            <SEO title="PCH | Chiropractors"/>
            <GeneralJumbotron
                title="Chiropractors"
                backgroundImageURL={queryResult?.chiropractorsBackgroundImage.publicURL}
                backgroundText="Chiropractors"
            />
            <SummaryTemplate image={queryResult?.chiropractorsSummaryImage.publicURL}>
                <p>
                    {`
                        Wouldn’t it be nice to have telemedicine as an option for chiropractic care?
                        With PCH IPA, you do! Through our state-of-the-art software, you can treat
                        up to 40 patients simultaneously. Our patented product follows guidelines
                        outlined by chiropractic laws in California. We are even offering you a
                        FREE EMR system!
                    `}
                </p>
                <p>
                    {`
                        By joining PCH IPA, you can access a marketplace that offers technology and
                        services promoting the exchange of clinical and administrative data in
                        real-time, leading to actionable intelligence and timely decision-making to
                        help manage risk and improve outcomes. As one of our providers, your
                        information will be accessible to clients across the state 24/7, resulting
                        in more referrals without you having to increase your overhead!
                    `}
                </p>
            </SummaryTemplate>
            <CallToAction/>
        </MainLayout>
    );
}

export default ChiropractorsPage;
